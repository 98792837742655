import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/#nadcha"> &gt; Radca(NÁDCHA)</a>
            <a href="/radca/nadcha/co_je_nadcha/" className="sel">
              {" "}
              &gt; Čo je nádcha?
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_680919733.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Čo je nádcha?</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <b>
                    Zápal nosovej sliznice, katar, vírusová{" "}
                    <a href="/slovnik_pojmov/#infekcia" target="_blank">
                      infekcia
                    </a>
                    &nbsp;horných dýchacích ciest, opuch nosovej sliznice –
                    všetky tieto ťažkosti ľudovo nazývame nádcha. Existuje
                    niekoľko typov nádchy, napr.: akútna (začína sa náhle a
                    obvykle ju vyvoláva vírusová infekcia), chronická (napr.
                    vyvolaná alergénmi) a epizodická (vzniká vplyvom
                    nešpecifických faktorov, ako je napríklad zmena teploty
                    vzduchu, cigaretový dym, telesná námaha alebo dráždivé
                    látky).{" "}
                  </b>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Infekčná nádcha – ochorenie, pred ktorým sa ťažko chrániť,
                    najčastejšie nás postihuje na jeseň a v zime. Proti nádche
                    neexistuje žiadne očkovanie a nakaziť sa ňou možno veľmi
                    rýchlo. Stačí podanie ruky v práci, alebo kýchajúci sused v
                    autobuse alebo električke. Klasickú nádchu síce zľahčujeme,
                    utešujeme sa slovami, že „liečená nádcha trvá sedem dní,
                    neliečená týždeň“, ale môže vyvolať závažné komplikácie,
                    napr. zápal prinosových dutín, zápal ucha, dokonca aj zápal
                    priedušiek. Vznikajúce infekcie navyše často poškodzujú
                    nosovú sliznicu, ktorá prestane plniť svoju ochrannú úlohu.{" "}
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Sliznica horných dýchacích ciest, presnejšie povedané
                    lepkavý hlien, ktorým je pokrytá, je istý druh pasce, do
                    ktorej sa chytia škodlivé vírusy. Tento hlien má izolovať a
                    zastaviť prienik prachu a peľu rastlín do pľúc. Nosová
                    sliznica je dobre pripravená na ochrannú úlohu. Pozostáva z
                    troch vrstiev: epitelu, základnej vrstvy a submukóznej
                    vrstvy.{" "}
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <img
                    alt="Zistite viac o nádche. Vyberte si OLYNTH® na uvoľnenie upchatého nosa."
                    src="/web/kcfinder/uploads/images/shutterstock_245721949.jpg"
                    style={{
                      height: "400px",
                      float: "left",
                      margin: "5px 10px 10px 0px",
                      "border-width": "2px",
                      "border-style": "solid",
                      width: "400px"
                    }}
                  />
                </p>
                <p
                  className="p4"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Prvá vrstva je vybavená riasinkami, ktoré sa začínajú vlniť a
                  rýchlo pohybovať (približne 1000-krát za minútu), keď sa
                  nahromadia rôzne nečistoty a vírusy. Vznikajúci proces vnímame
                  ako „šteklenie“ a svrbenie, v dôsledku čoho kýchame (čím
                  zároveň odstránime nečistoty z nosa). Zdravý nos účinne
                  zablokuje rozličné nečistoty. Ak je{" "}
                  <a href="/slovnik_pojmov/#imunitny_system" target="_blank">
                    imunitný systém
                  </a>
                  &nbsp;oslabený alebo nosová sliznica vysušená, vírusy sa
                  dostanú hlbšie, preniknú do buniek epitelu, rozmnožia sa a
                  vyvolajú zápalový proces. Náš imunitný systém v takom prípade
                  rýchlo zareaguje a na miesto infekcie vyšle biele krvinky
                  (lymfocyty). Cievky sa rozšíria, spôsobia opuch nosovej
                  sliznice (upchatie nosa) a intenzívnu tvorbu hlienov.
                  Konzistencia a farba hlienu sa postupom ochorenia mení.
                  Spočiatku je priesvitný a vodnatý. Narastajúci počet bielych
                  krviniek v ďalšej fáze zmení tekuté hlieny na bielozelený
                  alebo žltý, hustý maz, ktorý je ťažké odstrániť. Počiatočné
                  fázy vzniku prechladnutia sprevádzajú okrem upchatia nosa a
                  tekutého výtoku aj iné príznaky, ako napríklad: pocit choroby,
                  oslabenie, bolesť hlavy.
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Upchatý nos sťažuje dýchanie nosom, začíname preto dýchať
                    ústami. Studený vzduch, rôzne pele a choroboplodné
                    mikroorganizmy, ktoré sa v ňom nachádzajú, sa cez ústa
                    dostanú do priedušiek. V takom prípadne ešte ľahšie
                    „nachytáme“ ďalšie infekcie, ako sú napríklad rôzne infekcie
                    pľúc alebo priedušiek. Z tohto dôvodu je dôležité okamžite
                    začať liečbu a spriechodniť dýchacie cesty, aby sme znovu
                    mohli začať dýchať nosom. Nosové aerodisperzie, ako
                    napríklad OLYNTH<sup>®</sup>, môžu byť v takomto prípade
                    veľmi prínosné. Vďaka{" "}
                    <a href="/slovnik_pojmov/#xylometazolin" target="_blank">
                      xylometazolínu
                    </a>
                    , ktorý sa nachádza v nosových roztokových aerodisperziách{" "}
                    <a href="/lieky/olynth_01/" target="_blank">
                      OLYNTH<sup>®</sup> 0,1 %{" "}
                    </a>
                    ,{" "}
                    <a href="/lieky/olynth_ha_01/" target="_blank">
                      OLYNTH<sup>®</sup> HA 0,1 % a OLYNTH® PLUS
                    </a>
                    , dochádza k stiahnutiu cievok prítomných v nosovej sliznici
                    a k zmierneniu opuchu.{" "}
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p5"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p5"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <span className="s1">Literatúru:&nbsp;</span>
                  </span>
                </p>
                <p
                  className="p5"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <span className="s1">
                      P. Dąbrowski, Zapalenie górnych dróg oddechowych, [v:] „
                      <em>Przewodnik Lekarza</em>”, 2002, 5, 3, s. 37–42.
                    </span>
                  </span>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Ďalšie články</p>
                <a
                  href="/radca/nadcha/ako_si_spravne_cistit_nos/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg)"}} />
                  <span className="title">
                    Ako si správne čistiť nos pri nádche?
                  </span>
                </a>
                <a href="/radca/nadcha/spoznajte_typy_nadchy/" className="box">
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg)"}} />
                  <span className="title">Spoznajte typy nádchy</span>
                </a>
                <a
                  href="/radca/nadcha/zabojujte_s_nadchou_rychlo/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/shutterstock_152527238.jpg)"}} />
                  <span className="title">Zabojujte s nádchou rýchlo</span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
